.thin-line{
    width: auto;
    height: 1px;
    background-color: lightgray;
    margin: 20px 0;
}

.cart-container{
    width: 300px;
    min-height: 200px;
    padding: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: absolute;
    z-index: 5;
    border-radius: 5px;
    right: -95px; 
    top: 50px; 
    background-color: white;
}
h4{
    text-align: left;
    margin-bottom: 5px;
}

.items-container{
    margin: 8px 0;
}

.btn-containers{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon{
    font-size: 18px;
    color: white;
    cursor: pointer;
}

.sub-con{
    display: flex;
    align-items: center;
    background-color: hsl(26, 100%, 55%);
    padding: 8px 12px;
    width: 80%;
    border-radius: 5px;
    margin: 8px 5px;
    text-align: center;
    justify-content: space-around;
    cursor: pointer;
}

.clear-btn{
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
}

@media  screen and (max-width: 600px) {
    .cart-container{
        position: absolute;
        right: -39px;
        top: 65px;
    }
}