
a{
    text-decoration: none;
    color: black;
}

.nav-container{
    max-width: 1000px;
    margin: auto;
}
.navbar{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1000px;
    justify-content: space-between;
    text-align: center;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 10px;
}

.counter{
    position: relative;
}
.counter small{
    position: absolute;
    top: -6px;
    right: -5px;
    background-color: hsl(26, 100%, 55%);
    color: white;
    width: 15px;
    height: 15px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 10px;
}

ul{
    list-style-type: none;
}
ul li{
    display: inline;
    margin: 0 10px;

}
.icon-container{
    position: relative;
    padding: 12px;
}

.cart-icon{
    font-size: 20px;
}

.user-img{
    width: 35px;
    height: 35px;
    border: 3px solid hsl(26, 100%, 55%);
    border-radius: 50%;
}

.combine{
    display: flex;
    gap: 40px;
    align-items: center;
}

.thin-line{
    width: auto;
    height: 1px;
    background-color: lightgray;
}

.active{
    border-bottom: 3.2px solid hsl(26, 100%, 55%);
    padding-bottom: 29px;
}

ul li{
    color: rgb(138, 136, 136);
}

.sidenav-links li{
    color: black;
    display: block;
    margin: 18px 0px;
    padding: 10px;
}

.sidenav-links li:hover {
    background-color: rgb(201, 199, 199);
}

.sidenav-container{
    width: 230px;
    height: 100%;
    background-color: white;
    z-index: 2;
    overflow-x: hidden;
    transition: 1.5s;
}

.close-icon{
    font-size: 1.5rem;
    font-weight: bolder;
    color: black;
}

.sidenav-btn{
    background: transparent;
    border: none;
    padding: 8px;
    /* text-align: right; */
}

.sidenav-btn:hover{
    background-color: rgb(201, 199, 199);
    padding: 8px;
}

.logo{
    display: flex;
    align-items: center;
    gap: 15px;
}

.bar-btn{
    background: transparent;
    border: none;
    display: none;
}

.bar-icon{
    font-weight: bolder;
    font-size: 20px;
}

.sidenav-modal{
    background-color: hsla(0, 0%, 0%, 0.75);
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    position: fixed;
    overflow-x: hidden;
    transition: 1.5s;
    /* display: none; */
}



@media screen and (max-width: 600px) {
    .links{
        display: none;
    }
    .navbar{
        margin: 0 15px;
    }

    .combine{
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .bar-btn{
        display: block;
    }
}